import besterpLogo from "../../images/besterp_somos.png"; // Asegúrate de importar la imagen de tu logo

export const data = [
    {
      id: 1,
      img: 'https://www.coopacsancristobal.pe/wp-content/uploads/2023/03/LOGO-COOPAC-2020-PUBLICITARIO.png',
      
    },
    {
      id: 2,
      img: 'https://scontent.faqp1-1.fna.fbcdn.net/v/t1.6435-9/134983068_3599820596777586_3343995457192964432_n.png?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=zAPkFlmqeI8AX9ajor1&_nc_ht=scontent.faqp1-1.fna&oh=00_AfAzk5eD6i_w5oVcf6cPc-rOlHjCZ97D2Ls7WrfgPNiUxA&oe=662A7BD0',
    },
    {
      id: 3,
      img: 'https://coopaccuajone.com/wp-content/uploads/2023/07/logos_principal.png', 
    },


 

    {
      id: 5,
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL2FJKmhyYeOHELjvn34k5g37o4NKwE5aoFQlgnihj2w&s',
    },

  
    {
      id: 6,
      img: 'https://www.crediplata.com.pe/logo/Logo_principal.jpg',
    },


    {

      id: 7,
      img: 'https://coop15set.com.pe/web/wsp/img/logo-support.png', 
      

    },
    

    {
      id: 8,
      img: 'https://lh5.googleusercontent.com/proxy/VqMwZTf_PPShH844ph2cU71t5LGoRQWzfQMiiWNmMAOlkgLBnIn05nozNkhPt2qzUivuqRuNyvqB3jaW30FxBHr8mZ36jEUECUEr7K0YIqeE-7e9K6YDpfvmi4dvZPxpRReaHSeARamka-v1',
    },

    {
      id: 9,
      img: 'https://quillacoop.com.pe/teayudamos/logoblanco2.png',
    },

    

  ];