import React from 'react'
import Navbar from '../../components/navigation/navbar'
import Hero from '../../components/index/hero'
import Solutions from '../../pages/solutionsbp'
import Methodology from '../../components/methodology/methodology'
import GetContact from '../../pages/contact'
import Maintenance from '../../components/maintenance/Maintenance'
import Contacto from '../../components/contact/contact'
import Valores from '../../components/nosotros/Valores'
import Footer from '../../components/footer/footer'
function Home3() {

  return (
    <>
    
    <Navbar />
    <Contacto/>
    <Footer/>
       { /*  <Navbar />
        <Hero />
        <Maintenance/> */}

   
     
    </>
  )
}

export default Home3